import { SidebarRoot } from '@bluemarker/ui';
import { Outlet } from 'react-router';

import PlaygroundSidebar from './sidebar';

const PlaygroundAuthenticatedLayout = () => {
  return (
    <SidebarRoot>
      <div className="flex">
        <PlaygroundSidebar />
        <Outlet />
      </div>
    </SidebarRoot>
  );
};

export default PlaygroundAuthenticatedLayout;
