import { lazy } from 'react';
import type { RouteObject } from 'react-router';

const HistoryPage = lazy(() => import('./page'));

export const HistoryRoutes: RouteObject[] = [
  {
    path: '/history',
    Component: HistoryPage,
  },
];
