import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import {
  cn,
  Separator,
  SidebarCollapseButton,
  SidebarContent,
  SidebarLogo,
  SidebarNav,
  SidebarNavGroup,
  SidebarNavItem,
  useSidebarContext,
} from '@bluemarker/ui';
import { BookOpenCheckIcon, GalleryVerticalEndIcon, HomeIcon, LogOutIcon } from '@bluemarker/ui/icons';
import { Link, useLocation } from 'react-router';

import useSignOut from '@/apis/authentication/use-sign-out';

import SidebarUserDropdown from './sidebar-user-dropdown';

const TRANSLATION: I18nTranslation = {
  en: {
    home: 'Home',
    history: 'History',
    evaluate: 'Evaluate',
    practice: 'Practice',
    signOut: 'Sign out',
    help: 'Help',
  },
  vi: {
    home: 'Trang chủ',
    history: 'Lịch sử',
    evaluate: 'Đánh giá',
    practice: 'Luyện tập',
    help: 'Hỗ trợ',
    signOut: 'Đăng xuất',
  },
};

const PlaygroundSidebar = () => {
  const { pathname } = useLocation();
  const { isCollapsed } = useSidebarContext();

  const { t } = useExtendI18nTranslation(TRANSLATION);

  const { mutate: signOut } = useSignOut();

  return (
    <SidebarContent>
      <SidebarCollapseButton />

      <div className={cn(isCollapsed ? 'px-2' : 'px-6')}>
        <Link to="/">
          <SidebarLogo bluemarkerPlatform="Playground Platform" />
        </Link>

        <SidebarUserDropdown />

        <Separator />
      </div>

      <div className="flex flex-col flex-1 overflow-hidden my-3">
        <div className={cn('flex-1 overflow-auto space-y-2 py-1', isCollapsed ? 'px-2' : 'px-6')}>
          <SidebarNav label={t('home')} Icon={HomeIcon} active={pathname === '/'} href="/" />

          <SidebarNav
            label={t('history')}
            Icon={GalleryVerticalEndIcon}
            active={pathname === '/history'}
            href="/history"
          />

          <SidebarNav label="IELTS" Icon={BookOpenCheckIcon} active={pathname.startsWith('/ielts')}>
            <SidebarNavGroup label="IELTS Writing">
              <SidebarNavItem
                active={pathname === '/ielts/writing/evaluate-task-1'}
                href="/ielts/writing/evaluate-task-1"
              >
                {t('evaluate')} Task 1
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/writing/evaluate-task-2'}
                href="/ielts/writing/evaluate-task-2"
              >
                {t('evaluate')} Task 2
              </SidebarNavItem>
            </SidebarNavGroup>
            <SidebarNavGroup label="IELTS Speaking">
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-1'}
                href="/ielts/speaking/evaluate-part-1"
              >
                {t('evaluate')} Part 1
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-2'}
                href="/ielts/speaking/evaluate-part-2"
              >
                {t('evaluate')} Part 2
              </SidebarNavItem>
              <SidebarNavItem
                active={pathname === '/ielts/speaking/evaluate-part-3'}
                href="/ielts/speaking/evaluate-part-3"
              >
                {t('evaluate')} Part 3
              </SidebarNavItem>
            </SidebarNavGroup>

            <SidebarNavGroup label="IELTS Reading">
              <SidebarNavItem active={pathname === '/ielts/reading/practice'} href="/ielts/reading/practice">
                {t('practice')}
              </SidebarNavItem>
            </SidebarNavGroup>

            <SidebarNavGroup label="IELTS Listening">
              <SidebarNavItem active={pathname === '/ielts/listening/practice'} href="/ielts/listening/practice">
                {t('practice')}
              </SidebarNavItem>
            </SidebarNavGroup>
          </SidebarNav>
        </div>
      </div>

      <div className={cn(isCollapsed ? 'px-2' : 'px-6')}>
        <SidebarNav
          className="text-danger-4 hover:text-danger-4 hover:bg-danger-4/10"
          label={t('signOut')}
          Icon={LogOutIcon}
          active={false}
          handler={signOut}
        />
      </div>
    </SidebarContent>
  );
};

export default PlaygroundSidebar;
