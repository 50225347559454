import type { RouteObject } from 'react-router';

import PlaygroundAuthenticatedLayout from '@/layouts/authenticated/authenticated.layout';

import { HistoryRoutes } from './history/routes';
import { HomeRoutes } from './home/routes';
import { IeltsRoutes } from './ielts/routes';
import NotFoundPage from './not-found.page';

export const routes: RouteObject[] = [
  {
    path: '/',
    ErrorBoundary: NotFoundPage,
    children: [
      {
        Component: PlaygroundAuthenticatedLayout,
        children: [...HomeRoutes, ...HistoryRoutes, ...IeltsRoutes],
      },
      {
        path: '/404',
        Component: NotFoundPage,
      },
    ],
  },
];
