import { LoadingScreen } from '@bluemarker/ui';
import { type PropsWithChildren, useEffect } from 'react';

import useQueryUserProfile from '@/apis/authentication/use-query-user-profile';

const AuthenticatedGuard = ({ children }: PropsWithChildren) => {
  const { isLoading, isError } = useQueryUserProfile();

  useEffect(() => {
    if (isError) {
      window.location.href = import.meta.env.VITE_ACCOUNT_PLATFORM_URL + '/' + 'playground';
    }
  }, [isError]);

  if (isLoading || isError) {
    return <LoadingScreen />;
  }

  return children;
};

export default AuthenticatedGuard;
