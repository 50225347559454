import { lazy } from 'react';
import type { RouteObject } from 'react-router';

const HomePage = lazy(() => import('./page'));

export const HomeRoutes: RouteObject[] = [
  {
    path: '/',
    Component: HomePage,
  },
];
